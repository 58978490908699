
export const APP_SETTING = {

    /**
     * Domain Setup
     *
     * Specify the core domain and protocol of the application
     */
    domain: "metk.groon.io",
    protocol: "https",
    socket_protocol: 'wss',
    // domain: "localhost:8000",
    // protocol: "http",
    // socket_protocol: 'ws',

    launchpad: [
        {
            label: 'Create Text',
            description: 'Assistenten und individuelle KI Modelle, die Dich unterstützen.',
            value: '/on/beyond-hub/create-text',
            disabled: false,
            permissions: [],
            icon: 'File02',
        },
        {
            label: 'Create Images',
            description: 'Deine Bildsammlung und Verlauf an generierten Bildern und Visuals.',
            value: '/on/beyond-hub/create-images',
            disabled: false,
            permissions: [],
            icon: 'Image03',
        },
        {
            label: 'Projekte',
            description: 'Create Content, erledige Aufgaben, kommentiere und diskutiere.',
            value: '/on/beyond-hub/projects',
            disabled: false,
            permissions: [],
            icon: 'Headphones02',
        },
        {
            label: 'Stundenerfassung',
            description: 'Trage deine TROI Studen unkompliziert ein.',
            value: '/on/beyondh-hub/demo',
            disabled: true,
            permissions: [],
            icon: 'AlarmClock',
        },
        {
            label: 'Messenger',
            description: 'Schreibe mit deinen Kollegen und Teammitgliedern.',
            value: '/groon/chat',
            disabled: false,
            permissions: [],
            icon: 'MessageChatCircle',
        },
        {
            label: 'be.yond Hub',
            description: 'Nutze und erkunde die be.yond Methoden.',
            value: '/on/beyondh-hub/demo',
            disabled: true,
            permissions: [],
            icon: 'Rocket02',
        },
    ],

    /**
     * Sidebar
     *
     * Specify the sidebar items that shall be in the menu of this instance
     * Example:
     *      {
     *          title: 'Some Tool',
     *          path: '/custom-module/some-tool',
     *          icon: <FittingUntitledIcon />,
     *          items: [ ...children of the same type],
     *      },
     */
    navigation: {
        creativeStudio: {
            title: 'Kreativstudio',
            icon: 'Image02',
            path: '/groon/creative-studio',
            permissions: ['creative_studio'],
            items: [
                {
                    title: 'layout.creative_studio.creator_tools',
                    items: [
                        {
                            title: 'layout.creative_studio.image_creator',
                            path: '/groon/creative-studio/image-creator',
                            icon: 'Image01',
                            permissions: ["creative_studio"],
                            tooltip: 'layout.tooltips.creative_studio.image_creator'
                        },
                        {
                            title: 'layout.creative_studio.text_creator',
                            path: '/groon/creative-studio/text-creator',
                            icon: 'File05',
                            disabled: true,
                            permissions: ["creative_studio"],
                            tooltip: 'layout.tooltips.creative_studio.text_creator'
                        },
                    ]
                },
                {
                    title: 'layout.creative_studio.saved',
                    items: [
                        {
                            title: 'layout.creative_studio.created',
                            path: '/groon/creative-studio/created-media',
                            icon: 'Bookmark',
                            permissions: ["creative_studio"],
                            tooltip: 'layout.tooltips.creative_studio.created'
                        },
                        {
                            title: 'Collections',
                            path: '/groon/creative-studio/collections',
                            icon: 'Folder',
                            disabled: true,
                            permissions: ["creative_studio"],
                            // tooltip: 'layout.tooltips.creative_studio.created'
                        },

                        // TODO: Probably deprecated
                        // {
                        //     title: 'layout.creative_studio.projects',
                        //     path: '/groon/creative-studio/projects',
                        //     icon: 'Grid01',
                        //     permissions: ["creative_studio"],
                        //     tooltip: 'layout.tooltips.creative_studio.projects'
                        // },
                    ]
                }
            ]
        },
    },

    /**
     * Widgets
     *
     * Specify the widgets that shall be available in the dashboards of this instance
     * Example:
     *      {
     *          name: "Custom Widget",
     *          description: "This is a custom widget that does something",
     *          identifier: "UNIQUE-IDENTIFIER",
     *          module: "Custom Module",
     *          component: ReactComponentToUse
     *      },
     */
    widgets: [],

    /**
     * Services
     *
     * A list of all services (their identifiers!) that are available in this instance
     * Example:
     *     {
     *          name: "Service Name",
     *          identifier: "UNIQUE-IDENTIFIER"
     *     }
     */
    services: [
        {
            name: 'Beyond Hub | Light',
            identifier: 'beyond-hub-light',
            register_redirect: '/beyond-hub'
        },
        {
            name: 'Beyond Hub | Individual',
            identifier: 'beyond-hub-individual',
            register_redirect: '/beyond-hub'
        },
        {
            name: 'Beyond Hub | Occhio',
            identifier: 'beyond-hub-occhio',
            register_redirect: '/beyond-hub'
        },
    ],

    /**
     *
     */
    settings: [],

    /**
     * The duration of how long things fade in
     */
    transitionDuration: 500

}
