import { useEffect, useRef, useState } from "react";
import useOmniaApi from "./use-omnia-api";
import { useDispatch, useSelector } from "react-redux";
import {
    addEndpointToList,
    removeEndpointFromList,
} from "../store/actions/datatable-actions";

export function useGetElement(endpoint = null, showErrors = true, updateListener = null) {
    const { get } = useOmniaApi({ autoError: showErrors });
    const [loading, setLoading] = useState(true);
    const [element, setElement] = useState(null);
    const [updateListenerInitial, setUpdateListenerInitial] = useState(true);
    const dispatch = useDispatch();
    const requestIdRef = useRef(0);
    const updates = useSelector((state) => state.datatable.updates);
    const user = useSelector((state) => state.account.user);
    const updateTimestamp = updates?.[updateListener || endpoint] || 0;

    const prevConfigRef = useRef({
        endpoint: null,
        updateTimestamp: 0,
        groupId: user?.current_group?.id || null,
    });

    const reload = () => {
        const currentRequestId = ++requestIdRef.current;

        get(endpoint)
            .then((response) => {
                if (currentRequestId === requestIdRef.current) {
                    setElement(response);
                }
            })
            .catch((errors) => {
                console.error("Could not load element: ", errors);
            })
            .finally(() => {
                if (currentRequestId === requestIdRef.current) {
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        if (endpoint) {
            dispatch(addEndpointToList(endpoint));
        }
        return () => {
            if (endpoint) {
                dispatch(removeEndpointFromList(endpoint));
            }
        };
    }, [endpoint, dispatch]);

    useEffect(() => {
        if (endpoint) {
            setLoading(true);
            reload();
            prevConfigRef.current.endpoint = endpoint;
        } else {
            setLoading(false);
        }
    }, [endpoint]);

    useEffect(() => {
        if (!updateListenerInitial) {
            const prevUpdateTimestamp = prevConfigRef.current.updateTimestamp;
            const prevGroupId = prevConfigRef.current.groupId;

            // If there's a new timestamp or group ID changed, reload
            if (
                (updateTimestamp && updateTimestamp > prevUpdateTimestamp) ||
                (user?.current_group?.id && user?.current_group?.id !== prevGroupId)
            ) {
                reload();
                prevConfigRef.current.updateTimestamp = updateTimestamp;
                prevConfigRef.current.groupId = user?.current_group?.id;
            }
        } else {
            // On first mount, just flip the flag so we don't reload prematurely
            setUpdateListenerInitial(false);
            // Also update these so that future updates will compare properly
            prevConfigRef.current.updateTimestamp = updateTimestamp;
            prevConfigRef.current.groupId = user?.current_group?.id;
        }
    }, [updateTimestamp, user?.current_group?.id]);

    return {
        loading,
        setElement,
        reload,
        element,
    };
}

export default useGetElement;
