import React, {memo, useState} from "react";
import {
    Box,
    Card,
    CircularProgress,
    Dialog,
    Divider,
    Fab,
    IconButton,
    List,
    Stack, SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import ChatIcon from "@untitled-ui/icons-react/build/esm/MessageChatCircle";
import {useDispatch, useSelector} from "react-redux";
import {setChatOpen} from "../../storage/actions/beyond-actions";
import PropTypes from "prop-types";
import CloseIcon from "@untitled-ui/icons-react/build/esm/XClose";
import {useTheme} from "@mui/system";
import {useIsMobile} from "../../omnia/hooks/use-is-mobile";
import UploadingDialog from "../../omnia/components/elements/uploading-dialog";
import useServiceApi from "../../omnia/hooks/use-service-api";
import useGetElement from "../../omnia/hooks/use-get-element";
import {useTranslation} from "react-i18next";
import ChatViewer from "./chat-viewer";
import OnIcon from "../../omnia/components/elements/icon";
import {useLocation} from "react-router-dom";

const ChatWindow = memo(({endpointPrefix}) => {

    const theme = useTheme();
    const { t } = useTranslation();
    const chatOpen = useSelector(state => state.beyondHub.chatOpen);
    const dispatch = useDispatch();
    const assistantLoader = useGetElement(endpointPrefix + '/chats/default_assistant', false);
    const { uploadStatus, post } = useServiceApi({autoError: false});
    const [ isSending, setIsSending ] = useState(false);
    const { isMobile } = useIsMobile();

    const handleCloseChat = () => {
        dispatch(setChatOpen(false));
    }

    const handleSend = (values) => {
        return new Promise((resolve, reject) => {
            setIsSending(true);
            post('bh/chats/' + assistantLoader.element?.chat?.id + '/new_message', values).then(message => {
                resolve(message);
            }).catch(errors => {
                reject(errors);
            }).finally(() => {
                setIsSending(false);
            });
        });
    }

    const handleStopTyping = () => {
        // TODO: send this in the chat channel
    }

    const handleTyping = () => {
        // TODO: send this in the chat channel
    }

    const height = (isMobile ? 'calc(100vh - 150px)' : 500);

    if(!chatOpen)
        return null;

    const ChatComponent = (
        <Card
            style={ isMobile ? {
                height: '100vh'
            } : {
                position: 'fixed',
                backgroundColor: theme.palette.background.default,
                width: 380,
                zIndex: 1500,
                bottom: 0,
                right: 80,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                boxShadow: theme.shadows[21],
            }}
        >
            <UploadingDialog
                isUploading={isSending}
                uploadStatus={uploadStatus}
            />
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{p: 2}}
            >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    {assistantLoader.element?.assistant?.icon && (
                        <OnIcon iconName={assistantLoader.element?.assistant?.icon} />
                    )}
                    <Typography
                        variant="h6"
                        color="text.primary"
                    >
                        {assistantLoader.loading ? t('common.loading') : (assistantLoader.element ? assistantLoader.element?.assistant?.name : 'Assistant')}
                    </Typography>
                </Stack>
                <Tooltip title={t('common.close')} placement="top">
                    <IconButton onClick={handleCloseChat}>
                        <SvgIcon fontSize="small">
                            <CloseIcon />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Stack>
            <Divider />
            <Box px={1}>
                {assistantLoader.loading ? (
                    <div style={{height: height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <>
                        {assistantLoader.element?.chat?.id ? (
                            <ChatViewer
                                chat={assistantLoader.element?.chat || null}
                                height={height}
                                maxWidth={false}
                                smallVersion={true}
                            />
                        ) : (
                            <div style={{
                                height: height,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography variant="caption">
                                    Noch etwas Geduld, wir richten deinen Assistenten gerade ein
                                </Typography>
                            </div>
                        )}
                    </>
                )}
            </Box>
            {/*<ChatMessageAdd*/}
            {/*    disabled={!assistantLoader.element}*/}
            {/*    noAttachments*/}
            {/*    placeholder="Wie kann ich dir helfen?"*/}
            {/*    onSend={handleSend}*/}
            {/*    size="small"*/}
            {/*    smallVersion={true}*/}
            {/*    sending={isSending}*/}
            {/*    onTyping={handleTyping}*/}
            {/*    onStopTying={handleStopTyping}*/}
            {/*/>*/}
        </Card>
    );

    if(isMobile)
        return (
            <Dialog
                open={true}
                fullScreen={true}
            >
                {ChatComponent}
            </Dialog>
        )

    return ChatComponent;

}, (prev, next) => true)

const ChatButton = memo(() => {

        const dispatch = useDispatch();

        const handleOpenChat = () => {
            dispatch(setChatOpen(true));
        }

        return (
            <Tooltip
                title="Schreibe eine Nachricht an deinen Assistenten"
                placement="left"
            >
                <Fab
                    color="primary"
                    onClick={handleOpenChat}
                    aria-label="add"
                    sx={{position: 'fixed', bottom: 20, right: 20}}
                >
                    <ChatIcon />
                </Fab>
            </Tooltip>
        )

}, (prev, next) => {
    return prev.endpointPrefix === next.endpointPrefix;
})

function ChatWrapper({endpointPrefix, children}){

    const location = useLocation()

    if(location.pathname.includes('beyond-hub/chat') || location.pathname === '/beyond-hub/my-chats' || location.pathname === '/beyond-hub')
        return null;

    return (
        <>
            <ChatWindow endpointPrefix={endpointPrefix} />
            <ChatButton />
            {children}
        </>
    )
}

ChatWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    endpointPrefix: PropTypes.string
}

ChatWrapper.defaultProps = {
    endpointPrefix: 'bh'
}

export default ChatWrapper;